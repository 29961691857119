import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimeLineCard from './components/TimeLineCard';
import { Helmet } from 'react-helmet-async';

const Services = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>Services</title>
        </Helmet>
        <Typography variant="h4">Services</Typography>
        <Timeline position="alternate">
          <TimeLineCard />
        </Timeline>
      </Container>
    </Box>
  );
};

export default Services;
