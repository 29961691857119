import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const description ="Products list"
function Products() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>Products</title>
          <meta name="description" content={description} />
        </Helmet>
        <Typography variant="h4">Products</Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', lg: 'row', xl: 'row' },
            gap: 8,
            padding: 4,
            margin: 0,
          }}
        >
          <Box sx={{ display: 'block', width: '100%', margin: 0, padding: 0 }}>
            <Typography>
            Products List
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Products;
