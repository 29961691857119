import { Box, Container, CssBaseline, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 0,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>About Us</title>
        </Helmet>
        <Typography variant="h4">About Us</Typography>
        <CssBaseline />
        <Container maxWidth="lg">
          <main>
            <Typography variant="h4">Nexus Supplies Management</Typography>
            <Typography>
              At Nexus Supplies Management, we understand the critical role that
              a well-managed and efficient supply chain plays in the success of
              industrial operations. As a dedicated Industrial Supply Chain
              company, we specialize in providing a comprehensive range of raw
              materials and spare parts tailored to meet the unique needs of
              factories' production lines.
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 4 }}>
              Our Commitment to Excellence:
            </Typography>
            <Typography>
              <strong>Extensive Product Portfolio:</strong> Nexus Supplies
              Management boasts an extensive catalog of high-quality raw
              materials and spare parts sourced from trusted suppliers
              worldwide. From essential raw materials for manufacturing
              processes to precision-engineered spare parts for machinery, we
              have it all under one roof.
            </Typography>
            <Typography>
              <strong>Customized Solutions:</strong> Recognizing that each
              factory has distinct requirements, we offer customized supply
              chain solutions. Our team works closely with clients to understand
              their production processes, ensuring that we provide precisely the
              materials and parts needed for optimal efficiency.
            </Typography>
            <Typography>
              <strong>Reliability and Timeliness:</strong> We understand the
              significance of timely deliveries in industrial settings. Nexus
              Supplies Management is committed to reliable and on-time supply,
              reducing downtime and keeping production lines running smoothly.
              <Typography variant="h5" sx={{ marginTop: 4 }}>
                Key Areas of Expertise:
              </Typography>
              <strong>Raw Materials Procurement:</strong>Our sourcing team is
              dedicated to identifying and procuring top-quality raw materials
              essential for diverse manufacturing processes. Whether it's
              metals, plastics, chemicals, or any other raw material, we ensure
              consistent quality and competitive pricing.
            </Typography>
            <Typography>
              <strong>Spare Parts Management:</strong> Minimize the impact of
              equipment downtime with our comprehensive spare parts management
              services. We maintain a vast inventory of critical spare parts,
              reducing the lead time for replacements and repairs.
            </Typography>
            <Typography>
              <strong>Supply Chain Optimization:</strong> Leveraging the latest
              technologies and industry best practices, Nexus Supplies
              Management optimizes supply chains for increased efficiency. This
              includes inventory management, demand forecasting, and streamlined
              procurement processes.
            </Typography>
            <Typography variant="h5" sx={{ marginTop: 4 }}>
              Why Choose Nexus Supplies Management?
            </Typography>
            <Typography>
              <strong>Industry Expertise:</strong> With years of experience in
              industrial supply chain management, we bring industry expertise to
              every partnership.
            </Typography>
            <Typography>
              <strong>Global Network:</strong> Our extensive global network of
              suppliers ensures access to a diverse range of quality materials
              and parts.
            </Typography>
            <Typography>
              <strong>Customer-Centric Approach:</strong> We prioritize our
              clients' needs, providing personalized service and adaptable
              solutions.
            </Typography>
            <Typography sx={{ marginTop: 4 }}>
              Join hands with Nexus Supplies Management to elevate your
              industrial supply chain, reduce costs, and enhance the overall
              efficiency of your production processes. Together, let's build a
              foundation for sustainable growth and success.
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 4, marginBottom: 4}}>
              Contact us today to discuss how we can tailor our services to meet
              the unique requirements of your factory's production lines.
            </Typography>
          </main>
        </Container>
      </Container>
    </Box>
  );
};

export default About;
