import React from 'react';
import BuildIcon from '@mui/icons-material/Build';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Typography, useTheme, Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const concertsData = [
  { date: '10 January 2024', title: 'London ', location: 'Chelsea Bridge', link: ''},
  { date: '24 April 2023', title: 'Dubai', location: 'Khalifa Tower', link: '' },
  { date: '17 July 2023', title: 'Madrid', location: "Royal Palace of Madrid" },

].sort((a,b)=>{
  return new Date(b.date) - new Date(a.date);;
});

const TimelineCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
    {concertsData.map((c, index) => (
      <TimelineItem position={isMobile?"alternate":"right"} key={index}>
      <TimelineOppositeContent
        sx={{ m: 'auto 0', display: { xs: 'none', md: 'block' } }}
        align="right"
        variant="body2"
        color="text.secondary"
      >
        {c.date}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'primary.main' }}/>
        <TimelineDot color="primary" variant={index%2===0?"filled":"outlined"}>
          <BuildIcon />
        </TimelineDot>
        <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
      </TimelineSeparator>
      <TimelineContent sx= {index%2===0? { py: '12px', px:2 } : { py: '12px', px:2.1 }} >
        <Typography variant="h6" component="span">
         <Link href={c.link} target="_blank"> {c.title} </Link>
        </Typography>
        <Typography>{c.location}</Typography>
        <Typography
          variant="body2"
          component="span"
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          {c.date}
        </Typography>
      </TimelineContent>
    </TimelineItem>

    ))}
    </>
  );
};

export default TimelineCard;
