import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha'

import {
  Box,
  Typography,
  Card,
  Container,
  TextField,
  Button,
  Stack,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const FORM_ENDPOINT = "https://herotofu.com/start"; // TODO - update to the correct endpoint

const backgroundImage = '/images/contact-us.png';

const useStyles = makeStyles((theme) => ({
  boxWithImage: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: ' center',
    backgroundSize: 'cover',
    height: '40vh',
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    maskType: 'luminance',
  },
  text: {
    marginTop: '0', // Adjust the margin to control the gap between the image and text
  },
  // Define custom styles using media queries
  '@media (max-width: 600px)': {
    // Adjust styles for screens with a maximum width of 600px
    margin: 0,
    padding: 0,
    '.boxWithImage': {
      height: '40vh', // Adjust height for smaller screens
      padding: 0,
    },
    '.text': {
      marginTop: '10px', // Adjust margin for smaller screens
    },
  },
}));

const Contact = () => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (data) => {
    // Destrcture data object
    // const { name, email, subject, message } = data;
    data.preventDefault();

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Form response was not ok');
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        data.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon.</div>
      </>
    );
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <Container
        sx={{
          mt: 4,
          padding: 4,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '14px',
          width: '100%',
        }}
      >
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <Typography variant="h4">Contact Us</Typography>
        <Box className={classes.boxWithImage}>
          <Box
            sx={{
              position: 'relative',
              top: '70px',
              width: { xl: '40%', sm: '70%', xs: '90%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              margin: 2,
              padding: { xs: 4, sm: 8, md: 4, xl: 12 },
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            padding: 4,
            gap: 2,
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}
          >
            <Card
              sx={{ width: '50%', padding: '20px', backgroundColor: '#f2f2f2' }}
            >
              <Typography variant="h5">London Office</Typography>
              <Typography>7c, Burlington Gardens</Typography>
              <Typography>London, England</Typography>
              <Typography>PostCode W3 6BA</Typography>
              <Typography>Email: info[at]nexus-supplies.com</Typography>
            </Card>
            <img
              Src="/images/location.jpg"
              alt="location"
              width={340}
              height={280}
            />
            <Card
              sx={{ width: '50%', padding: '20px', backgroundColor: '#f2f2f2' }}
            >
              <Typography variant="h5">Turkey Office</Typography>
              <Typography>Kemal Paşa mahallesi, Ordu caddesi, </Typography>
              <Typography>Yeşil tulumba Sokak No 4A Fatih, İstanbul</Typography>
              <Typography>Posta kodu 34134</Typography>
            </Card>
          </Box>
        </Box>
        <form onSubmit={handleSubmit} action={<Link to="/login" />}>
          <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              fullWidth
              required
            />
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              fullWidth
              required
            />
          </Stack>
          <TextField
            type="email"
            variant="outlined"
            color="secondary"
            label="Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            required
            sx={{ mb: 4 }}
          />
          <TextField
            type="textarea"
            variant="outlined"
            color="secondary"
            label="Message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
            fullWidth
            sx={{ mb: 4 }}
          />
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} />
          <Button sx={{marginTop: '20px'}} variant="outlined" color="secondary" type="submit">
            Submit
          </Button>
</form>
        
      </Container>
    </Box>
  );
};

export default Contact;
