import { createTheme } from '@mui/material/styles';
import { green, grey, red } from '@mui/material/colors';

const rawTheme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 2000,
      xl: 2000,
    },
  },
  palette: {
    primary: {
      light: '#585859',
      main: '#28282a',
      dark: '#1e1e1f',
    },
    secondary: {
      light: '#4B498C',
      main: '#0E1626',
      dark: '#e62958',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
  },
});

const fontHeader = {
  color: rawTheme.palette.text.primary,
  fontWeight: rawTheme.typography.fontWeightMedium,
  fontFamily: "'Roboto Condensed', sans-serif",
  textTransform: 'uppercase',
};

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: grey[200],
    },
  },
  typography: {
    ...rawTheme.typography,
    fontHeader,
    h1: {
      ...rawTheme.typography.h1,
      ...fontHeader,
      letterSpacing: 0,
      fontSize: 60,
    },
    h2: {
      ...rawTheme.typography.h2,
      ...fontHeader,
      fontSize: 48,
    },
    h3: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
    },
    h3W: {
      ...rawTheme.typography.h3,
      ...fontHeader,
      fontSize: 42,
      padding: 10,
      color: rawTheme.palette.common.white,
      // background: `linear-gradient(to right, ${rawTheme.palette.primary.main} 20%, rgba(255,0,0,0))`,
      // '-webkit-text-stroke-width': '2px',
      // '-webkit-text-stroke-color': '#fff',
    },
    h4: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 30,
      align: 'left',
    },
    h4W: {
      ...rawTheme.typography.h4,
      ...fontHeader,
      fontSize: 30,
      align: 'left',
      padding: 10,
      color: rawTheme.palette.common.white,
      // background: `linear-gradient(to right, ${rawTheme.palette.primary.main} 20%, rgba(255,0,0,0))`,
      // '-webkit-text-stroke-width': '2px',
      // '-webkit-text-stroke-color': '#fff',
    },
    h5: {
      ...rawTheme.typography.h5,
      fontSize: 30,
      fontWeight: rawTheme.typography.fontWeightBold,
    },
    h6: {
      ...rawTheme.typography.h6,
      ...fontHeader,
      fontSize: 18,
    },
    subtitle1: {
      ...rawTheme.typography.subtitle1,
      fontSize: 16,
    },    
    body1: {
      ...rawTheme.typography.body2,
      fontWeight: rawTheme.typography.fontWeightRegular,
      fontSize: "1.5rem",
    },
    body2: {
      ...rawTheme.typography.body1,
      fontSize: 16,
    },
  },
};

export default theme;
