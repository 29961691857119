import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box, Container } from '@mui/material';
import { Navigate } from 'react-router-dom';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import withRoot from './modules/withRoot';
import MenuBar from './modules/MenuBar';
import Home from './modules/Home';
import Footer from './modules/components/Footer';
import Contact from './modules/Contact';
import Products from './modules/Products';
import Services from './modules/Services';
import About from './modules/About';

import './App.css';

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 400,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#top-menu',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function Index(props) {
  const location = useLocation();
  const currentURL = location.pathname;
  const [currentPage, setCurrentPage] = useState(currentURL);
  return (
    <HelmetProvider>
      <Helmet>Home
        </Helmet> <Box sx={{ width: '100%', margin: 0, padding: 0 }}>
        <MenuBar setCurrentPage={setCurrentPage} />
        <Container
          sx={{
            width: '100%',
            padding: { xs: 0, sm: 0, lg: 0, xl: 0 },
            margin: 0,
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Contact us" element={<Contact />} />
            <Route path="/ABOUT US" element={<About />} />
            <Route path='*' element={<Home />}/>
          </Routes>
        </Container>
      </Box>
      <Navigate to={currentPage} replace={true} />
      <ScrollTop {...props}>
        <Fab size="medium" aria-label="scroll back to top" color='secondary'>
          <UpIcon />
        </Fab>
      </ScrollTop>
      <Footer />
  </HelmetProvider>
  );
}

export default withRoot(Index);
