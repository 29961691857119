import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Link,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Copyright = () => {
  return (
    <Typography variant="subtitle1" sx= {{ textAlign:'center' }}>
      All rights reserved by nexus-supplies.com
    </Typography>
  );
};

const Footer = () => {
  return (
    <footer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
          gap: 1,
          padding: '10px',
        }}
      >
        <List
          orientation="horizontal"
          wrap
          sx={{
            display: 'flex',
            // backgroundColor: '#454545',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: { xs:'100%', md:'50%'},
            padding: '40px 40px 0 0',
          }}
        >
          <ListItem sx={{ width: { xs: '50%', md: '50%' } }}>
            <List>
              <ListItem>
                <ListItemButton href="/Home">Home</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/Products">Products</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/Services">Services</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ width: { xs: '50%', md: '50%' } }}>
            <List>
              <ListItem>
                <ListItemButton href="/Contact">Contact us</ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton href="/About us">About us</ListItemButton>
              </ListItem>
            </List>
          </ListItem>
        </List>
        <Box
          variant="soft"
          size="sm"
          sx={{
            display: 'flex',
            // backgroundColor: '#454554',
            flexDirection: { xs: 'row', md: 'row' },
            width: { xs: '100%', md: 'auto' },
            alignItems: 'end',
            gap: 2,
            justifyContent: { xs:'center', md:'start' },
            padding: 2,
            paddingLeft: {xs: 0, md:4},
          }}
        >
          <Link color="#ffffff" href="https://www.instagram.com">
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
          </Link>
          <FontAwesomeIcon icon={faTwitter} size="2xl" />
          <FontAwesomeIcon icon={faLinkedin} size="2xl" />
          <Link color="#ffffff" href="/contact" alt="info @ nexus-supplies.com">
          <FontAwesomeIcon icon={faEnvelope} size="2xl" />
          </Link>
        </Box>
      </Box>
      <Copyright />
    </footer>
  );
};

export default Footer;
